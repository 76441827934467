const INTER = [
  { space_src: "baidujs", space_type: "inters", space_id: "u6916171", space_js: "//xsfpb.moonreader.cn/site/je/common/d/source/mnd/static/nf/n.js" }
];
const HOMEDIALOG = [{ space_src: "baidujs", space_type: "inters", space_id: "u6916172", space_js: "//xsfpb.moonreader.cn/site/k-f-eno/source/eogu.js" }];
const TOPNATIVE = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916173", space_js: "//xsfpb.moonreader.cn/source/l/production/gf/common/opf/static/ph_y.js" }
];
const BANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6916174", space_js: "//xsfpb.moonreader.cn/site/m/source/hg-pqg/qi/static/d.js" }];
const IDIOMLISTNATIVE = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916173", space_js: "//xsfpb.moonreader.cn/source/l/production/gf/common/opf/static/ph_y.js" }
];
const IDIOMLISTBANNER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916174", space_js: "//xsfpb.moonreader.cn/site/m/source/hg-pqg/qi/static/d.js" }
];
const IDIOMNATIVE = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916173", space_js: "//xsfpb.moonreader.cn/source/l/production/gf/common/opf/static/ph_y.j" }
];
const IDIOMBANNER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916174", space_js: "//xsfpb.moonreader.cn/site/m/source/hg-pqg/qi/static/d.js" }
];
const IDIOMINTER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916171", space_js: "//xsfpb.moonreader.cn/site/je/common/d/source/mnd/static/nf/n.js" }
];
const STORYNATIVE = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916173", space_js: "//xsfpb.moonreader.cn/source/l/production/gf/common/opf/static/ph_y.js" }
];
const STORYBANNER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916174", space_js: "//xsfpb.moonreader.cn/site/m/source/hg-pqg/qi/static/d.js" }
];
const STORYINTER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916171", space_js: "//xsfpb.moonreader.cn/site/je/common/d/source/mnd/static/nf/n.js" }
];
const INTERESTGUESSERROR = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916172", space_js: "//xsfpb.moonreader.cn/site/k-f-eno/source/eogu.js" }
];
const INTERESTGUESSNATIVE = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916173", space_js: "//xsfpb.moonreader.cn/source/l/production/gf/common/opf/static/ph_y.js" }
];
const GUESSNEXTNATIVE = [{ space_src: "baidujs", space_type: "native", space_id: "u6916172", space_js: "//xsfpb.moonreader.cn/site/k-f-eno/source/eogu.js" }];
const GUESSBANNER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6916174", space_js: "//xsfpb.moonreader.cn/site/m/source/hg-pqg/qi/static/d.js" }
];

/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: INTER, // 插屏
  Home_dialog: HOMEDIALOG, // 自定义插屏
  Home_top: TOPNATIVE, // 首页信息流
  Home_banner: BANNER, //首页banner
  Idiom_list_native: IDIOMLISTNATIVE, // 成语二级列表信息流
  Idiom_list_banner: IDIOMLISTBANNER, // 成语二级列表banner
  Idiom_native: IDIOMNATIVE, // 成语详情信息流
  Idiom_banner: IDIOMBANNER, // 成语详情banner
  Idiom_inter: IDIOMINTER, // 成语详情官方插屏
  Story_native: STORYNATIVE, //故事详情信息流
  Story_banner: STORYBANNER, //故事详情banner
  Story_inter: STORYINTER, // 故事详情官方插屏
  Interest_guesss_error: INTERESTGUESSERROR, //趣味猜成语错误弹窗广告
  Interest_guesss_native: INTERESTGUESSNATIVE, //趣味猜成语信息流
  Guess_next_native: GUESSNEXTNATIVE, //猜成语查看答案插屏广告
  Guess_banner: GUESSBANNER //猜成语banner
};
